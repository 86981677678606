
import './App.css';
import React, { useState } from 'react';
import { useExitIntent } from 'use-exit-intent'
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';

function App() {
  const { registerHandler } = useExitIntent()
  const [showPopup, setShowPopup] = useState(false)
  const [showPopupC, setShowPopupC] = useState(false)
  const [problem, setProblem] = useState('')
  const [email, setEmail] = useState('')

  const handleClose = () => {
    setShowPopup(false);
  };

  const handleClose2 = () => {
    setShowPopupC(false);
  };
  const handleSave = () => {
    const bdy = {'problem':problem,'email':email};
    fetch("https://calcbo.se/cta/askforhelp", {
      body: JSON.stringify(bdy),
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST"
    })
    .then((response) => response.json())
    .then((res) => {
      setShowPopupC(true)
      setShowPopup(false)
    })
  };

  registerHandler({
    id: 'openModal',
    handler: () => setShowPopup(true)
  })

  return (
    <div>
      <Dialog open={showPopup} onClose={handleClose}>
        <DialogTitle>Behöver du mer hjälp?
          <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton></DialogTitle>
        <DialogContent>
          <DialogContentText>
            Få 30 minuters personlig hjälp gratis.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="problem"
            label="Problem du vill ha hjälp med"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            value={problem}
            onChange={(event) => {
              setProblem(event.target.value);
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave}>Starta</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showPopupC} onClose={handleClose2}>
        <DialogTitle>Tack för din förfrågan
          <IconButton
          aria-label="close"
          onClick={handleClose2}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton></DialogTitle>
        <DialogContent>
          <DialogContentText>
            Vi kontaktar dig med svar inom 24 timmar.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2}>Stäng</Button>
        </DialogActions>
      </Dialog>


    </div>
  );
}

export default App;
